import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Accroche from "@/Widgets/Home/Accroche";
import SearchWide from "@/Widgets/Home/SearchWide";
import PasserelleUsage from "@/Widgets/Home/PasserelleUsage";
import PasserelleMarques from "@/Widgets/Home/PasserelleMarques";
import PasserelleComparateur from "@/Widgets/Home/PasserelleComparateur";
import PasserelleCMS from "@/Widgets/Home/PasserelleCMS";
// import CarteDistributeurs from '@/Widgets/CarteDistributeurs'
import DialogInfoImage from "@/Shared/Ui/DialogInfoImage";
import logoYescapa from "@/Images/yescapa/LOGOYescapa.png";
import {
  HomeParagraph,
  SeoParagraph,
} from "@/Shared/SeoParagraph/SeoParagraph.jsx";

const Home = ({ extraits, ...props }) => {
  const [isDialogShowing, setIsDialogShowing] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsDialogShowing(false);
    return () => {
      setIsDialogShowing(false);
    };
  }, []);

  useEffect(() => {
    if (router.query.from === "yescapa") setIsDialogShowing(true);
  }, [router]);

  return (
    <>
      <Accroche />
      <SearchWide />
      {isDialogShowing && (
        <DialogInfoImage
          handleClose={() => setIsDialogShowing(false)}
          open={isDialogShowing}
          objet='Yescapa Occasions fait une pause.'
          message='Nous avons le plaisir de vous rediriger vers WeekandGO pour vendre ou acheter votre prochain véhicule de loisirs d’occasion !'
          submit='Je découvre WeekandGO!'
          image={logoYescapa}
          variationStyle='yescapa'
        />
      )}
      <PasserelleUsage />
      <PasserelleMarques />
      <PasserelleComparateur />
      <PasserelleCMS extraits={extraits} />
      <SeoParagraph>{HomeParagraph}</SeoParagraph>
      {/* #SLEEPING <CarteDistributeurs />*/}
    </>
  );
};

export default Home;
