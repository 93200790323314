import React, { useEffect, useRef } from "react";
//import PropTypes from 'prop-types';
import css from "./PasserelleUsage.module.css";
import Svg from "@/Shared/Svg";
import { scrollTriggerFade } from "@/Shared/Animate";

import Btn from "@/Shared/Ui/Btn";

const PasserelleUsage = () => {
  let blocUsage = useRef(null);
  useEffect(() => scrollTriggerFade(blocUsage, false), []);

  return (
    <>
      <section
        className={`${css["l-passerelle-usage"]} l-marges-fixes gsap__autoalpha`}
        ref={el => (blocUsage = el)}
      >
        <div className={`${css["c-passerelle-usage"]}`}>
          <svg
            preserveAspectRatio='none'
            className={`${css["gfx__bottom"]}`}
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 800 20'
          >
            <path d='M0 20L800 5.333V0H0z' />
          </svg>
          <div className={`${css["c-passerelle-usage__tip"]}`}>
            <p className={"f-primary f-primary--300"}>
              Un petit coup de pouce pour vous aiguiller dans votre choix&nbsp;?
            </p>
          </div>
          <div className={`${css["c-passerelle-usage__visavis"]}`}>
            <div className={`${css["c-passerelle-usage__question"]}`}>
              <h1
                className={`${css["c-passerelle-usage__question__titre"]} f-primary f-primary--400`}
              >
                Quel <span className={"f-primary--700"}>Camping-car</span> en
                fonction de mon usage&nbsp;?
              </h1>
              <div className={`${css["c-passerelle-usage__descriptif"]}`}>
                <Svg selection='ico-roueloupe' />
                <p>
                  {
                    "Le Van est-il fait pour vous ? Voyager en famille.  Partir léger sur un coup de tête. L'aventure sans compromis, Une utilisation au quotidien, Le luxe au grand air, L'appréhension d'une première fois"
                  }
                </p>
              </div>
            </div>
            <Btn
              type='link'
              href='/blog/acheter-vehicule-loisirs/quel-vehicule-de-loisirs-est-fait-pour-vous'
              className={`${css["c-passerelle-usage__link"]}`}
            >
              <Svg selection='submit-go' classNames='svg__submit-go--invert' />
            </Btn>
          </div>
        </div>
      </section>
    </>
  );
};

PasserelleUsage.propTypes = {
  //
};

export default PasserelleUsage;
