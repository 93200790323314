import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
// TODO TEST @MUI with jest
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Btn from "@/Shared/Ui/Btn";
import cssThemeEspace from "@/Theme/EspaceTheme.module.css";
import css from "./DialogInfoImage.module.css";
import { Image } from "@/Shared/ImageWithFallback";

const DialogInfoImage = ({
  handleClose,
  open,
  objet,
  message,
  submit,
  image,
  variationStyle,
}) => {
  const label = (submit = submit ?? "Retour");
  return (
    <>
      {/* -------------------------- */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth={"b60"}
        className={`${cssThemeEspace["dialog-box"]} ${css[""]}`}
      >
        <DialogTitle id='alert-dialog-title'>
          <Image
            src={image}
            layout='responsive'
            objectFit='contain'
            alt={objet}
            key={image}
          />
          <span
            className={`f-primary f-primary--700 ${cssThemeEspace["dialog-box__title"]}`}
          >
            {objet}
          </span>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Btn
            type='text'
            className={`${cssThemeEspace["containerFormulaire__submit__btn"]} 
              ${cssThemeEspace["containerFormulaire__submit__btn--condensed"]} 
              f-primary f-primary--700
            `}
            label={label}
            onClick={handleClose}
          />
        </DialogActions>
      </Dialog>
      {/* -------------------------- */}
    </>
  );
};

DialogInfoImage.propTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  objet: PropTypes.string,
  message: PropTypes.string,
  submit: PropTypes.string,
};

export default DialogInfoImage;
