import { useEffect, useRef, useState } from "react";
import { bindActionCreators } from "redux";
//import PropTypes from 'prop-types';
//import BtnFilter from "@/Shared/Ui/BtnFilter";
import Svg from "@/Shared/Svg";
// import css from './SearchWide.module.css';

import css from "./SearchWide.module.css";
import { appearFromY } from "@/Shared/Animate";

import BlockCritere from "@/Shared/SearchEngine/BlockCritere";
import Btn from "@/Shared/Ui/Btn";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";
import { ThemeProvider } from "@mui/material/styles";
import wagoTheme from "@/Theme/themeWagoMui";
import { connect } from "react-redux";

import { useRouter } from "next/dist/client/router";
import { changeAdsListFromFormSubmit } from "@/Actions/ads";
import { ADS_FILTERS } from "@/Constants/filters";
import { getFiltersCol1, getFiltersCol2 } from "@/Utils/filtersFunctions";
import { PAGE_RECHERCHE_VEHICULE } from "@/Constants/pageRoutes";

const SearchWide = ({ ads, changeAdsListFromFormSubmit }) => {
  let blockRecherche = useRef(null);
  const router = useRouter();

  const [filtersCol1, setFiltersCol1] = useState();
  const [filtersCol2, setFiltersCol2] = useState();

  const handleSubmit = e => {
    e.preventDefault();
    changeAdsListFromFormSubmit();
    router.push(PAGE_RECHERCHE_VEHICULE);
  };

  useEffect(() => {
    appearFromY(blockRecherche, 0.3, -40);
  }, []);

  useEffect(() => {
    let filtersData = { ...ADS_FILTERS };

    if (ads.filters) {
      filtersData = ads.filters;
    }

    if (filtersData) {
      setFiltersCol1(getFiltersCol1(filtersData));
      setFiltersCol2(getFiltersCol2(filtersData));
    }
  }, [ads.filters]);

  return (
    <section
      className={`s-search s-search--wide ${css["l-search-wide"]} l-marges-etroites-fixes`}
    >
      <div
        className={`${css["c-search"]} u-box-white u-box-shadow gsap__autoalpha`}
        ref={el => (blockRecherche = el)}
      >
        <h1 className={css["c-search__titre"]}>
          <Svg selection='trouvez' />
          <span className={"f-primary"}>
            votre prochain véhicule de loisirs
          </span>
        </h1>
        <ThemeProvider theme={wagoTheme}>
          <form
            onSubmit={handleSubmit}
            className={`${css["c-search__form"]} search-wide-split`}
          >
            <div className={css["c-search__form__split"]}>
              {/* CRITERES TYPE NEUF - OCCASION && CARROSSERIES */}
              <div className={css["c-search__groupe"]}>
                {filtersCol1?.map(([name, filter]) => (
                  <BlockCritere
                    cols={2}
                    key={name}
                    name={name}
                    filter={filter}
                  />
                ))}
              </div>

              {/* CRITERES COUCHAGES */}
              <div
                className={`c-search__form__couchage ${css["c-search__groupe"]} ${css["c-search__groupe__2x"]}`}
              >
                {filtersCol2?.map(([name, filter]) => (
                  <BlockCritere
                    cols={3}
                    key={name}
                    name={name}
                    filter={filter}
                  />
                ))}
              </div>

              {/* CRITERES PRIX && LONGUEUR && PLACES DE COUCHAGE */}
              <div
                className={`${css["c-search__groupe"]} ${css["c-search__group__triple"]} u-triple-wide`}
              >
                <BlockCritere
                  name={"inclVatPrice"}
                  filter={ads.filters?.inclVatPrice}
                />
                <BlockCritere
                  name={"size"}
                  filter={ads.filters?.size?.values?.[0]}
                  parent={ads.filters?.size}
                />
                <BlockCritere
                  name={"sleepingSeats"}
                  filter={ads.filters?.sleepingSeats}
                />
              </div>
            </div>
            <Btn type='submit' className={uiBtn["u-btn__go"]} svg='submit-go' />
          </form>
        </ThemeProvider>
      </div>
    </section>
  );
};

SearchWide.propTypes = {
  //
};

export default connect(
  store => ({ ads: store.ads }),
  dispatch => ({
    changeAdsListFromFormSubmit: bindActionCreators(
      changeAdsListFromFormSubmit,
      dispatch
    ),
  })
)(SearchWide);
