import React, { useEffect, useRef } from "react";
//import PropTypes from 'prop-types';
import css from "./Accroche.module.css";
import Svg from "@/Shared/Svg/";
import { appearFromY } from "@/Shared/Animate";

const Accroche = () => {
  let accroche = useRef(null);
  useEffect(() => appearFromY(accroche, 0, 40), []);

  return (
    <>
      <section className={`${css["l-heros-accueil"]}`}>
        <div
          className={`${css["c-heros__accueil"]} gsap__autoalpha`}
          ref={el => (accroche = el)}
        >
          <div
            className={`f-primary ${css["c-heros__accueil__baseline"]} f-primary--700`}
          >
            WeekandGO vous donne{" "}
            <span className={`${css["insecable"]}`}>
              les
              <Svg selection='ico-cles' />
            </span>
          </div>
          <div
            className={`f-primary f-primary--300 ${css["c-heros__accueil__desc"]}`}
          >
            WeekandGO vous accompagne pour trouver le véhicule qui vous
            correspond
          </div>
        </div>
      </section>
    </>
  );
};

Accroche.propTypes = {
  //
};

export default Accroche;
