import React, { useEffect, useRef } from "react";
//import PropTypes from 'prop-types';
import css from "./PasserelleComparateur.module.css";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";
// import css from '@/Shared/Ui/BtnFilter/BtnFilter.module.css';
import Svg from "@/Shared/Svg";
import Btn from "@/Shared/Ui/Btn";
import { scrollTriggerFade } from "@/Shared/Animate";

const PasserelleComparateur = () => {
  let blocComparateur = useRef(null);
  useEffect(() => scrollTriggerFade(blocComparateur, false), []);

  return (
    <>
      <section
        className={`${css["l-passerelle-comparateur"]} l-marges-fixes`}
        ref={el => (blocComparateur = el)}
      >
        <div className={`${css["c-passerelle-comparateur"]}`}>
          <div className={`${css["gfx__dometop"]}`}>
            <Svg selection='gfx-dome-centered-top' />
          </div>
          <Svg
            selection='gfx-wave-bottom'
            classNamesGlobal={css["gfx__wavebottom"]}
          />
          <Svg selection='ico-comparateur' />
          <h1 className={`${css["c-passerelle-comparateur__titre"]} f-primary`}>
            <strong>{"J'hésite"}</strong> entre plusieurs véhicules,
            <span>
              {" "}
              comment <strong>arrêter mon choix ?</strong>
            </span>
          </h1>
          <Btn
            className={`${uiBtn["u-btn-blanc"]} ${uiBtn["u-btn-ico"]} ${uiBtn["u-btn-ico--d"]} ${uiBtn["u-btn-hoverSlide"]} ${uiBtn["u-btn-hoverSlide--secondary"]}`}
            type='routeLink'
            path='/comparateur-vehicule-loisirs'
            svg='ico-arrow'
            svgHover={"ico-hover-blanc"}
            label='Comparez les modèles qui vous intéressent'
          />
        </div>
      </section>
    </>
  );
};

PasserelleComparateur.propTypes = {
  //
};

export default PasserelleComparateur;
