import React, { useEffect, useRef } from "react";
//import PropTypes from 'prop-types';
import css from "./PasserelleCMS.module.css";
import CMSMosExtraits from "@/Widgets/Blog/CMSMosExtraits";
import Svg from "@/Shared/Svg";
import { scrollTriggerFade } from "@/Shared/Animate";
import ContentExtrait from "@/Widgets/Blog/ContentExtrait";

const PasserelleCMS = ({ extraits }) => {
  let blocCMS = useRef(null);
  useEffect(() => scrollTriggerFade(blocCMS, false), []);

  return (
    <>
      <section
        className={`${css["l-passerelle-cms"]} ${css["l-passerelle-cms--tmpmargin"]} l-marges-etroites-fixes`}
        ref={el => (blocCMS = el)}
      >
        <div className={`${css["c-passerelle-cms"]}`}>
          <div className={"c-passerelle-cms__blockintro"}>
            <div className={`${css["c-passerelle-cms__blockico"]}`}>
              <Svg selection='ico-video' />
              <Svg selection='ico-checklist' />
              <Svg selection='ico-crayonmetre' />
            </div>
            <div className={`${css["c-passerelle-cms__ttl1"]}`}>
              <h1
                className={`${css["c-passerelle-cms__ttl1__main"]} f-primary`}
              >
                Le <strong className={"f-primary--700"}>blog</strong>
              </h1>
              <div
                className={`${css["c-passerelle-cms__ttl1__sub"]} f-primary f-primary--100`}
              >
                {"Toute l'info qu'il vous faut"}
              </div>
            </div>
          </div>

          <div className={`${css["c-passerelle-cms__ttl2"]}`}>
            <h2 className={`${css["c-passerelle-cms__soustitre"]} f-primary`}>
              Nos <strong>derniers articles</strong>
            </h2>
          </div>

          <div className={`${css["c-passerelle-cms__content"]}`}>
            {extraits?.length ? (
              <CMSMosExtraits variante='inPasserelle'>
                {extraits.map(extrait => (
                  <ContentExtrait
                    variante='inPasserelle'
                    extrait={extrait}
                    rubriqueSlug={extrait.category.slug}
                    key={extrait.slug}
                    typePage={
                      extrait.body?.find(
                        section => section.__component === "sections.video"
                      ) !== undefined
                        ? "video"
                        : "text"
                    }
                  />
                ))}
              </CMSMosExtraits>
            ) : (
              <p>Aucun article</p>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

PasserelleCMS.propTypes = {
  //
};

export default PasserelleCMS;
