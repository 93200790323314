import React, { useContext, useEffect } from "react";
import Home from "@/Layouts/Home";
import { normalizeAccueilProps } from "@/Utils/normalizers/strapiApiNormalizers";
import { fetchStrapiApi } from "@/Utils/http/strapiApiRequests";
import revalidate from "@/Utils/revalidate";
import { FIVE_SECONDS } from "@/Constants/time";
import { GlobalContext } from "@/Shared/GlobalContextProvider/GlobalContextProvider";
import { restApiGetFiltersBrands } from "@/Utils/http/businessApiRequests";
import {
  handleSessionFetchingByPhase,
  prepareServerProps,
} from "@/Utils/index";
import { serialize } from "@/Utils/serializers/businessApiSerializers";
import Seo from "@/Shared/Seo";
import { testLocators } from "@/Constants/testLocators";
import { SEO } from "@/Constants/seo";

const Accueil = ({ homepage, articles, filteredBrands, ...props }) => {
  const { updateFilteredBrands } = useContext(GlobalContext);

  useEffect(async () => {
    await updateFilteredBrands();
  }, []);

  return (
    <div data-testid={testLocators.pages.index.title}>
      <Seo seo={SEO.index} />
      <Home extraits={articles} {...props} />
    </div>
  );
};

export const getStaticProps = async context => {
  const session = await handleSessionFetchingByPhase(
    process.env.NEXT_PHASE,
    context
  );

  const arrayPropNames = ["articles", "homepage", "filteredBrands"];
  const arrayRequestCallbacks = [
    async () =>
      fetchStrapiApi("/articles").then(data => normalizeAccueilProps(data)),
    async () => fetchStrapiApi("/homepage").then(data => serialize(data)),
    async () => restApiGetFiltersBrands(),
  ];
  const options = { arrayPropNames, arrayRequestCallbacks };

  return {
    ...(await prepareServerProps(session, context, options)),
    revalidate: revalidate(FIVE_SECONDS),
  };
};

export default Accueil;
