import { useEffect, useRef, useState } from "react";
import uiBtn from "@/Shared/Ui/Btn/Btn.module.css";
import css from "./PasserelleMarques.module.css";
import Logo from "@/Shared/Logo";
import Btn from "@/Shared/Ui/Btn";
import { scrollTriggerFade } from "@/Shared/Animate";

import { updateAdsFiltersAndAds } from "@/Actions/ads";
import { useRouter } from "next/dist/client/router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { PAGE_RECHERCHE_VEHICULE } from "@/Constants/pageRoutes";
import { convertArrayBrandsToFilterAdsBrandsCriteriaValues } from "@/Utils/filtersFunctions";
import { BRANDS_FAVORITES_ROUTE } from "@/Constants/restApiRoutes";
import { businessApiRequest } from "@/Utils/http/businessApiRequests";

const PasserelleMarques = ({ ads, updateAdsFiltersAndAds }) => {
  const router = useRouter();

  const [arrayBrandsValues, setArrayBrandsValues] = useState(null);
  const [pickedBrandValue, setPickedBrandValue] = useState();

  let blocMarques = useRef(null);

  const updateAdsFilterBrandCriteria = (ads, pickedBrandValue) => {
    // https://redux.js.org/usage/structuring-reducers/immutable-update-patterns
    // making a shallow copy of the array with spread operator is needed

    let criteriaBrandValues = [...ads.filters["model.brand"].values];

    let isPickedBrandFound = false;
    let iteration = 0;

    while (!isPickedBrandFound && iteration < criteriaBrandValues.length) {
      const currentBrand = criteriaBrandValues[iteration];

      isPickedBrandFound = currentBrand.id === pickedBrandValue.id;

      if (isPickedBrandFound) {
        criteriaBrandValues[iteration].checked = true;
      }

      if (!isPickedBrandFound && iteration === criteriaBrandValues.length) {
        criteriaBrandValues = [pickedBrandValue, ...criteriaBrandValues];
      }

      iteration++;
    }

    const adsFilter = {
      ...ads.filters,
      [ads.filters["model.brand"].name]: {
        ...ads.filters["model.brand"],
        values: [...criteriaBrandValues],
      },
    };

    updateAdsFiltersAndAds({ ...adsFilter });
  };

  useEffect(() => {
    scrollTriggerFade(blocMarques, false);
  }, []);

  useEffect(async () => {
    if (ads.filters["model.brand"]?.values && !arrayBrandsValues) {
      const arrayFavoriteBrands = await businessApiRequest(
        BRANDS_FAVORITES_ROUTE
      );

      if (arrayFavoriteBrands?.length) {
        const arrayFavoriteBrandValues =
          convertArrayBrandsToFilterAdsBrandsCriteriaValues(
            arrayFavoriteBrands
          );

        setArrayBrandsValues(arrayFavoriteBrandValues);
      }
    }
  }, [ads?.filters["model.brand"]?.values]);

  useEffect(() => {
    if (pickedBrandValue && ads.filters?.["model.brand"]?.values) {
      updateAdsFilterBrandCriteria(ads, pickedBrandValue);
      router.push(PAGE_RECHERCHE_VEHICULE);
    }
  }, [pickedBrandValue]);

  return (
    <section
      className={`${css["l-passerelle-marques"]} l-marges-etroites-fixes gsap__autoalpha`}
      ref={el => (blocMarques = el)}
    >
      <div className={css["c-passerelle-marques"]}>
        <h1
          className={`${css["c-passerelle-marques__titre"]} f-primary f-primary--300`}
        >
          Vos marques préférées sont sur <Logo situation='inline-titre' />{" "}
        </h1>
        <div className={`${css["c-passerelle-marques__nuage"]}`}>
          {arrayBrandsValues?.map(brandValue => (
            <Btn
              type='button'
              key={brandValue.id}
              onClick={() => setPickedBrandValue(brandValue)}
              className={`${uiBtn["u-btn-text"]} 
            ${uiBtn["u-btn-text--upper"]} ${uiBtn["u-btn-hoverSlide"]} 
            ${uiBtn["u-btn-hoverSlide--gris"]}`}
              label={brandValue.label}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default connect(
  store => ({ ads: store.ads }),
  dispatch => ({
    updateAdsFiltersAndAds: bindActionCreators(
      updateAdsFiltersAndAds,
      dispatch
    ),
  })
)(PasserelleMarques);
